angular.module('bfControllers.reclamations',
    [
        //'oi.select'
    ]
)
.controller('ReclamationsController', ['$scope', '$rootScope', '$http', '$resource', '$cookies', 'handbook', 'reclamation', 'helpers', function ($scope, $rootScope, $http, $resource, $cookies, handbook, reclamation, helpers) {

    $scope.reclamation = reclamation;
    $scope.selected_requests = {
        ids: []
    };

    //$rootScope.create = request.setRequest;
    $rootScope.counts = {};
    $rootScope.handbook = handbook;

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    $rootScope.show_value = [];

    $rootScope.show_from = 0;
    $scope.daterange = new helpers.daterange();

    $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE,41);

    $rootScope.plantations_in_item = {};

    var self = this;

    this.showForm = function(id){
        $scope.reclamation.set(id);
        // Открываем диалог
        $('#bf-create-reclamation-dialog').modal('show');
    };
    $rootScope.showForm = this.showForm;

    this.showAddForm = function(id){
        $scope.reclamation.set(id);
        // Открываем диалог
        $('#bf-add-files-reclamation-dialog').modal('show');
    };
    $rootScope.showAddForm = this.showAddForm;

    /**
     * Get list reclamations
     */
    $rootScope.renewItems = $scope.pagination.renewPage = function(){
        $rootScope.querySent = true;
        // http://ec.term.biflorica:8080/api/reclamations
        var show;
        if(typeof $rootScope.show_value[0] != 'undefined'){
            show = $rootScope.show_value;
        }else{
            show = 'all';
        }
        self.saveUpFilterToCookies();
        $resource(API_LINK + '/reclamations/:action',
            {
                action:'index',
                sort: $rootScope.sort,
                show_from: $rootScope.show_from,
                limit: $scope.pagination.itemsPerPage,
                page: $scope.pagination.currPage,
                startDate: $scope.daterange.startDate,
                endDate: $scope.daterange.endDate,
                direct: $rootScope.direct
            },
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.items = result.data.list;
            $rootScope.plantations_in_item = result.data.plantations;
            $rootScope.counts = result.data.counts;
            var count = parseInt(result.data.count_items);
            var total = parseInt(result.data.total_items);
            $scope.pagination.recountPages(count, total);
            //$scope.renewButtonsStatus();
            $rootScope.querySent = false;
        });
    };

    $scope.renewTimer = function(){
        if(!$rootScope.pause) $rootScope.timerCountSec--;
        if($rootScope.timerCountSec <= 0){
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $scope.renewItems();
        }
        $('#timer_value').text($rootScope.timerCountSec);
        setTimeout($scope.renewTimer, 1000);
    };
    $scope.renewTimer();

    /**
     * Delete items
     */
    $rootScope.delete = function () {
        url = API_LINK + '/reclamations/delete/';
        $rootScope.querySent = true;
        var sent_params = {};
        sent_params['checked[]'] = $scope.getIds();
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.errors.length == 0) {
                //location.reload();
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
            //$rootScope.querySent = false;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Download all invoices for order deals
     */
    $rootScope.allInvoices = function () {
        $http({
            method: 'POST',
            url: API_LINK + '/orders/createZipInvoices/id/' + show_order_id,
            //data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();
                // Show Mail Info Popup
                $('#zipInvoicesForm').modal('show');
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_requests.ids = [];
        if($('#list-requests-all').is(':checked')){
            for (var request in $scope.requests) {
                $scope.selected_requests.ids[request] = {num: parseInt(request), id: $scope.requests[request].guid}; //$scope.requests[request].guid;
                //console.log($scope.requests[request].guid);
            }
            $('.request-checkbox').attr('checked', true);
        }
        //console.log($scope.selected_orders);
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_requests.ids = [];
        $('#list-requests-all').attr('checked',false);
        //console.log($scope.selected_orders);
    };
    $scope.uncheckAll = this.uncheckAll;

    $scope.getIds = function () {
        var ret_arr = [];
        for(var req in $scope.selected_requests.ids) {
            //var num_in_row = $scope.selected_requests.ids[req].num;
            //var request_id = $scope.selected_requests.ids[req].id;
            ret_arr.push($scope.selected_requests.ids[req].id);
        }
        return ret_arr;
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('sort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'created';
        $cookies.put('sort', 'created');
    }
    var directValueFromCookies = $cookies.get('direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
        $cookies.put('direct', 'desc');
        $rootScope.timerCountSec = 0;
    }
    this.setSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc');
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc');
            }
        }else{
            // Изменяем сортировку
            $cookies.put('sort', sort_column);
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };

    $rootScope.setShowFrom = function(plantation_id){
        $rootScope.show_from = plantation_id;
        $rootScope.renewItems();
    };

    /**
     * Approve before cancel deal
     */
    $rootScope.approveBeforeCancelDeal = function (deal_id) {
        $rootScope.cancel_deal_request_id = deal_id;
        $('.bf-approve-cancel-deal-dialog').modal('show');
    };

    /**
     * Снять рекламацию
     * http://ec.term.biflorica:8080/api/Deals/cancel/
     * dealids%5B%5D=20
     */
    $rootScope.withdraw = function(deal_id){
        $http({
            method: 'GET',
            url: API_LINK + '/reclamations/withdraw/id/'+deal_id,
            //data: $.param({'deal_id': deal_id}),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log(result);
            location.reload();
        }, function(err){
            console.log(err);
        });
    };

    // Выбранные значения фильтра вверху
    var upFilterValueFromCookies = $cookies.get('upfilter_reclamation');
    if(typeof upFilterValueFromCookies != 'undefined'){
        $rootScope.show_value = upFilterValueFromCookies;
    }else{
        $rootScope.show_value = [];
        $cookies.put('upfilter_reclamation', $rootScope.show_value);
    }

    this.saveUpFilterToCookies = function(){
        $cookies.put('upfilter_reclamation', $rootScope.show_value);
    };

    /**
     * Проверка отображения плантации в фильтре
     * @param plantation_id
     * @returns {boolean}
     */
    $scope.checkPlantation = function(plantation_id){
        if(typeof $rootScope.plantations_in_item[plantation_id] != 'undefined'){
            return true;
        }else{
            return false;
        }
    };

    // Запуск действий при загрузке страницы
    $rootScope.renewItems();
} ]);