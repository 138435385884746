angular.module('bfServices.counter', [])

.service('counter', ['$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {

    this.UPDATE_PERIOD = 60; // sec
    this.sellerOwnRequestCount = START_OWN_REQUESTS_COUNT;
    this.sellerAutoDealsCount = START_AUTO_DEALS_COUNT;

    var self = this;

    this.updateSellerCounters = function() {
        if ($rootScope.current_user_role != "seller")
            return

        url = API_LINK + '/marketPlace/sellerCounters';

        $http({
            method: 'GET',
            url: url,
        }).then(function( result ) {
            self.sellerOwnRequestCount = result.data.data.own_requests_count;
            self.sellerAutoDealsCount = result.data.data.auto_deals_count;
        })
    };

    this.renewTimerForSellers = function(){
        self.updateSellerCounters();
        setTimeout(self.renewTimerForSellers, self.UPDATE_PERIOD * 1000);
    };

    this.renewTimerForSellers();
}]);
