angular.module('bfControllers.requests', 
    [
        //'oi.select'
    ]
)
.controller('RequestsController', ['$scope', '$rootScope', '$http', '$resource', '$cookies', 'handbook', 'request', 'reclamation', 'commet', 'utils', 'helpers', 'changeprice',
    function ($scope, $rootScope, $http, $resource, $cookies, handbook, request, reclamation, commet, utils, helpers, changeprice) {

    $scope.request = request;
    $scope.composition = {};
    $scope.composition_total = {};

    $scope.selected_requests = {
        ids: []
    };

    $scope.request.editedRequest.links = {};
    $scope.request.editedRequest.itemsHasAtLeastOneLink = false;

    $rootScope.deals_page = typeof deals_page !== 'undefined' ? deals_page : false;

    if (!$rootScope.deals_page) {
        $rootScope.order_purpose = orders_purpose;
    }

    $rootScope.filter_cookie_name = $rootScope.deals_page ? 'upfilter_deals' : 'upfilter_request';
    $rootScope.default_show_value = $rootScope.deals_page ? ["deals", "waiting_confirm", "booked", "cancel"] : ["on_market", "deals", "off_market", "expired", "cancel"];
    /* $rootScope.default_show_value = $rootScope.deals_page ? ["deals"] : ["on_market", "deals", "off_market", "expired", "cancel"]; */

    $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE,41);
    $scope.show_order_id = show_order_id;

   $scope.daterange = new helpers.daterange();

    $rootScope.create = request.setRequest;
    $rootScope.counts = {};
    $rootScope.countsPerOrder = {};
    $rootScope.data_for_operation = {
        order_id: 0,
        sent_params: '',
        move: true
    };
    $rootScope.handbook = handbook;
    $rootScope.reclamation = reclamation;

    this.path_alias = '/marketPlaceNew/default';

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.platform_id = PLATFORM_ID;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    $rootScope.changeprice = changeprice;

    /**
     * Отображаемые заявки фильтр в тайтлзоне (on_market, deals, off_market, expired)
     */
    //Включить чек-боксы по-умолчанию
    $rootScope.show_value = [];

    var self = this;

    this.showRequestForm = function(reqid){
        request.clone_request = false;
        request.formType = 'create';
        request.set(reqid, show_order_id);
        // Открываем диалог
        $('.bf-create-request-dialog').modal('show');
    };
    $rootScope.create = this.showRequestForm;

    /**
     * Возвращает IDs заявок на текущей странице грида
     */
    this.getGridRequestIds = function() {
        return utils.getGridEntityIds($scope.requests);
    };

    /**
     * Возвращает IDs заказов на текущей странице грида
     */
    this.getGridOrderIds = function() {
        // для страницы сделок возвращаем пустой массив, так как там нет заказов
        if ($rootScope.deals_page) {
            return [];
        }

        if(many_orders == 1){
            return orders_array;
        } else {
            return [show_order_id];
        }
    };

    /**
     * Изменение количества коробок в фильтре у заказа
     *
     * @param data
     */
    $rootScope.changeOrderInGrid = function (data) {
        var accordance = {'onMarket': 'on_market', 'offMarket': 'off_market', 'deals': 'deals', 'expMarket': 'expired', 'cancel': 'cancel'};
        var currentCounts = $rootScope.countsPerOrder[data['guid']];

        for (var prop in data) {
            if (data.hasOwnProperty(prop) && accordance.hasOwnProperty(prop) && currentCounts.hasOwnProperty(accordance[prop])) {
                currentCounts[accordance[prop]] = parseFloat(data[prop]).toFixed(1);
            }
        }

        $rootScope.counts = $rootScope.calculateOrderCounts($rootScope.countsPerOrder);
        $scope.$apply();
    };

    /**
     * Get list requests
     */
    $rootScope.renewRequests = $scope.pagination.renewPage = $scope.daterange.renewPage = function(){
        console.log($rootScope.show_value);
        commet.untrackRequests(self.getGridRequestIds());
        commet.untrackOrders(self.getGridOrderIds());
        $rootScope.querySent = true;
        // http://ec.term.biflorica:8080/api/requests/list/orders/3?request_id=0&start=0&sort=utime&show%5B%5D=all&direct=desc&limit=127
        var show;
        if(typeof $rootScope.show_value[0] != 'undefined'){
            show = $rootScope.show_value;
        }else{
            show = 'all';
        }
        self.saveUpFilterToCookies();
        $resource(API_LINK + '/requests/:action',
            {
                action:$rootScope.deals_page ? 'dealsList' : 'list',
                'orders': show_order_id,
                'show[]': show,
                sort: $rootScope.sort,
                limit: $scope.pagination.itemsPerPage,
                page: $scope.pagination.currPage,
                direct: $rootScope.direct,
                new_market: 1,
                startDate: $scope.daterange.startDate,
                endDate: $scope.daterange.endDate,
                order_purpose: $rootScope.order_purpose
            },
            {get: {method:'GET'}}
        ).get(function(result){
            var count = parseInt(result.data.count_items);
            var total = parseInt(result.data.total_items)
            $scope.pagination.recountPages(count, total);

            $scope.requests = result.data.list;
            $scope.type = result.data.type;

            if (typeof result.data.countsPerOrder !== 'undefined') {
                $rootScope.counts = $rootScope.calculateOrderCounts(result.data.countsPerOrder);
                $rootScope.countsPerOrder = result.data.countsPerOrder;
            } else {
                $rootScope.counts = result.data.counts;
            }

            $rootScope.counts = result.data.counts;

            // необходимо перезаписать выбранные Request IDs с новыми номерами строк
            if ($scope.selected_requests.ids.length > 0) {
                var selectedIds = $scope.getIds();

                $scope.selected_requests.ids = [];
                for (var request in $scope.requests) {
                    if ($scope.requests.hasOwnProperty(request) && selectedIds.indexOf($scope.requests[request].guid) !== -1) {
                        $scope.selected_requests.ids[request] = {num: parseInt(request), id: $scope.requests[request].guid};
                    }
                }
            }

            $scope.renewButtonsStatus();
            $rootScope.querySent = false;
            commet.trackRequests(self.getGridRequestIds());
            commet.trackOrders(self.getGridOrderIds());

        });
    };

    /**
     * Для каждого заказа приходит отдельная статистика по статусам
     * Считаем сумму по всем заказам и выводим
     *
     * @param counts
     */
    $rootScope.calculateOrderCounts = function (counts) {
        var total = {};

        for (num in counts) {
            if (!counts.hasOwnProperty(num)) {
                continue;
            }

            for (var prop in counts[num]) {
                if (counts[num].hasOwnProperty(prop)) {
                    var value = parseFloat(counts[num][prop]);
                    if (isNaN(value)) {
                        continue;
                    }

                    if (typeof total[prop] === 'undefined') {
                        total[prop] = value;
                    } else {
                        total[prop] += value;
                    }
                }
            }
        }

        for (var prop in total) {
            if (total.hasOwnProperty(prop)) {
                total[prop] = total[prop].toFixed(1);
            }
        }

        return total;
    };


    $scope.renewTimer = function(){
        if(!$rootScope.pause) $rootScope.timerCountSec--;
        if($rootScope.timerCountSec <= 0){
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $scope.renewRequests();
        }
        $('#timer_value').text($rootScope.timerCountSec);
        setTimeout($scope.renewTimer, 1000);
    };
    $scope.renewTimer();

    /**
     * Изменение состояния заявки в гриде
     *
     * @param data
     */
    $rootScope.changeRequestInGrid = function (data) {
        if (utils.changeEntityInGrid(data, $scope.requests)) {
            $scope.$apply();
        }
    };

    /**
     * Действия, которые необходимо выполнить после восстановления соединения с сервером ноды
     */
    $rootScope.$on('afterReconnect', function(event, data) {
        $rootScope.renewRequests();
    });

    /**
     * Move requests to market
     */
    $rootScope.moveToMarket = function () {
        url = API_LINK + '/requests/moveToMarketPlace/';
        $rootScope.querySent = true;
        var sent_params = {};
        sent_params['checked[]'] = $scope.getIds();
        sent_params['order_purpose'] = $rootScope.order_purpose;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();

            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            //$rootScope.querySent = false;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };



    $rootScope.setQualityControl = function () {
        url = API_LINK + '/requests/setQualityControl/';
        $rootScope.querySent = true;
        var sent_params = {};
        sent_params['checked[]'] = $scope.getIds();
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            $rootScope.timerCountSec = 0;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Move requests from market
     */
    $rootScope.moveFromMarket = function () {
        url = API_LINK + '/requests/removeFromMarketPlace/';
        $rootScope.querySent = true;
        var sent_params = {};
        sent_params['checked[]'] = $scope.getIds();
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();

            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            //$rootScope.querySent = false;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Delete requests
     */
    $rootScope.delete = function () {
        url = API_LINK + '/requests/delete/';
        $rootScope.querySent = true;
        var sent_params = {};
        sent_params['checked[]'] = $scope.getIds();
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();

            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            //$rootScope.querySent = false;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Move requests to another order (show form for select order)
     */
    $rootScope.move = function () {
        var sent_params = {};
        sent_params['requests[]'] = $scope.getIds();
        $rootScope.data_for_operation.sent_params = sent_params;
        $rootScope.data_for_operation.move = true;
        $('#selectOrder').modal('show');
        self.timerCountSec = 0;
        //window.location.replace('/marketPlace/orders/viewUngrouped/?' + $.param(sent_params));
    };

    /**
     * Move requests to another order
     */
    $rootScope.moveRequests = function () {
        url = API_LINK + '/requests/moveToOrder/';
        $rootScope.querySent = true;
        var sent_params = $rootScope.data_for_operation.sent_params;
        sent_params['order'] = $rootScope.data_for_operation.order_id;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();
                $('#selectOrder').modal('hide');
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            //$rootScope.querySent = false;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Copy requests to another order (show form for select order)
     */
    $rootScope.copy = function () {

        $scope.request.getActiveOrders();

        var sent_params = {};
        sent_params['requests[]'] = $scope.getIds();
        $rootScope.data_for_operation.sent_params = sent_params;
        $rootScope.data_for_operation.move = false;
        $('#selectOrder').modal('show');
        $rootScope.timerCountSec = 0;
        //window.location.replace('/marketPlaceNew/mover/index?' + $.param(sent_params));
    };

    /**
     * Copy requests to another order
     */
    $rootScope.copyRequests = function () {
        url = API_LINK + '/requests/copyToOrder/';
        $rootScope.querySent = true;

        var sent_params = $rootScope.data_for_operation.sent_params;
        sent_params['order'] = $rootScope.data_for_operation.order_id;

        window.location.replace('/marketPlaceNew/mover/index?' + $.param(sent_params));
        /*
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();
                $('#selectOrder').modal('hide');
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            //$rootScope.querySent = false;

            console.log(result);
        }, function(err){
            console.log(err);
        });
        */
    };

    /**
     * Show reports for order
     */
    $rootScope.report = function () {
        var param_str = '';
        if(many_orders == 1){
            for(order_id in orders_array){
                param_str = param_str + 'orders[]=' + orders_array[order_id] + '&';
            }
        }else{
            param_str = param_str + 'orders[]=' + show_order_id;
        }
        window.location.replace('/api/requests/GetCSVList/?' + param_str);
    };

    /**
     * Show reports for deals (Deals page)
     */
    $rootScope.reportDeals = function () {
        var show = '';
        if (typeof $rootScope.show_value[0] !== 'undefined') {
            show = $rootScope.show_value;
        }

        var param_str = 'dealsPage=1&order_purpose='+$rootScope.order_purpose+'&startDate=' + $scope.daterange.startDate + '&endDate=' + $scope.daterange.endDate + '&';

        if (show !== '' && show.indexOf('deals') !== -1) {
            param_str = param_str + 'show[]=deals&';
            window.location.replace('/api/requests/GetCSVList/?' + param_str);
        }
    };

    /**
     * Download all invoices for order deals
     */
    $rootScope.allInvoices = function () {
        $http({
            method: 'POST',
            url: API_LINK + '/orders/createZipInvoices/id/' + show_order_id,
            //data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();
                // Show Mail Info Popup
                $('#zipInvoicesForm').modal('show');
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Download all invoices for deals
     */
    $rootScope.allInvoicesDeals = function () {
        $resource(API_LINK + '/requests/createZipInvoices',
            {
                startDate: $rootScope.startDate,
                endDate: $rootScope.endDate,
                purpose: $rootScope.order_purpose
            },
            {get: {method: 'GET'}}
        ).get(function (result) {
            console.log(result);
        });
    };

    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_requests.ids = [];
        if($('#list-requests-all').is(':checked')){
            for (var request in $scope.requests) {
                $scope.selected_requests.ids[request] = {num: parseInt(request), id: $scope.requests[request].guid}; //$scope.requests[request].guid;
                //console.log($scope.requests[request].guid);
            }
            $('.request-checkbox').attr('checked', true);
        }
        //console.log($scope.selected_orders);
    };

    /**
     * Group cancel deals
     */
    $rootScope.cancelDeals = function () {
        // API_LINK + '/deals/approveCancel/id/'+deal_id,
        $http({
            method: 'POST',
            //url: API_LINK + '/Deals/cancel',
            url: API_LINK + '/deals/approvesCancel',
            data: $.param({'dealids[]': $scope.getIDForCancels()}),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            $rootScope.cancel_deal_request_id = 0;
            $rootScope.timerCountSec = 0;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_requests.ids = [];
        $('#list-requests-all').attr('checked',false);
        //console.log($scope.selected_orders);
    };
    $scope.uncheckAll = this.uncheckAll;

    /**
     * Обновить статус кнопок груповых операций
     */
    $scope.renewButtonsStatus = function () {
        if($scope.selected_requests.ids.length === 0){
            // Disable buttons
            $('.group-requests-btn').attr('disabled', 'disabled');
        }else{
            // Enable buttons
            $('.group-requests-btn').attr('disabled', 'disabled');

            // Включаем кнопки которые предназначены для любых статусов
            $('.group-requests-btn-any').removeAttr('disabled');

            // Перебор заявок и включение кнопок согласно статусов
            for(var req in $scope.selected_requests.ids){
                var num_in_row = $scope.selected_requests.ids[req].num;
                var request_id = $scope.selected_requests.ids[req].id;

                if($scope.requests[num_in_row].status_real.toLowerCase() == "on_market")
                    $('.group-requests-btn-on_market').removeAttr('disabled');

                if($scope.requests[num_in_row].status_real.toLowerCase() == "off_market")
                    $('.group-requests-btn-off_market').removeAttr('disabled');

                if($scope.requests[num_in_row].status_real.toLowerCase() == "expired")
                    $('.group-requests-btn-off_market').removeAttr('disabled');

                if($scope.requests[num_in_row].status_real.toLowerCase() == "deal" && $scope.requests[num_in_row].need_approve)
                    $('.group-requests-btn-maybe_canceled').removeAttr('disabled');

            };
        }

        // Активируем кнопки общего назначения
        if ($rootScope.deals_page) {
            if($rootScope.counts.invoices != '0.0' && $rootScope.show_value.indexOf('deals') !== -1) {
                $('.group-requests-btn-deals').removeAttr('disabled');
            } else  {
                $('.group-requests-btn-deals').attr('disabled', true);
            }

            if ($rootScope.counts.invoices != '0.0' && $rootScope.show_value.indexOf('deals') !== -1) {
                $('.group-requests-btn-invoices').removeAttr('disabled');
            } else {
                $('.group-requests-btn-invoices').attr('disabled', true);
            }
        } else {
            if($rootScope.counts.invoices != '0.0') {
                $('.group-requests-btn-deals').removeAttr('disabled');
            }

            if ($rootScope.counts.invoices != 0) {
                $('.group-requests-btn-invoices').removeAttr('disabled');
            }
        }

        //console.log($scope.selected_requests);
    };

    $scope.getIds = function () {
        var ret_arr = [];
        for(var req in $scope.selected_requests.ids) {
            //var num_in_row = $scope.selected_requests.ids[req].num;
            //var request_id = $scope.selected_requests.ids[req].id;
            ret_arr.push($scope.selected_requests.ids[req].id);
        }
        return ret_arr;
    };
    $rootScope.getIds = $scope.getIds;

    $scope.getIDForCancels = function () {
        var ret_arr = [];
        for(var req in $scope.selected_requests.ids){
            var num_in_row = $scope.selected_requests.ids[req].num;
            var request_id = $scope.selected_requests.ids[req].id;

            if($scope.requests[num_in_row].status_real.toLowerCase() == "deal" && $scope.requests[num_in_row].need_approve)
                ret_arr.push($scope.requests[num_in_row].cancel_id);
        };
        return ret_arr;
    };

    $rootScope.getIDsForChangePrice = function () {
        var ret_arr = [];
        for (var req in $scope.selected_requests.ids) {
            if ($scope.requests[$scope.selected_requests.ids[req].num].status_real.toLowerCase() == "on_market" || $scope.requests[$scope.selected_requests.ids[req].num].status_real.toLowerCase() == "off_market") {
                ret_arr.push($scope.selected_requests.ids[req].id);
            }
        }
        return ret_arr;
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('sort_requests');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'utime';
        $cookies.put('sort_requests', 'utime', {path: self.path_alias});
    }
    var directValueFromCookies = $cookies.get('direct_requests');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
        $cookies.put('direct_requests', 'desc', {path: self.path_alias});
        $rootScope.timerCountSec = 0;
    }
    this.setSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('direct_requests', 'desc', {path: self.path_alias});
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('direct_requests', 'asc', {path: self.path_alias});
            }
        }else{
            // Изменяем сортировку
            $cookies.put('sort_requests', sort_column, {path: self.path_alias});
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };

    $scope.getButtonClass = function(actiontype){
        var class_str = '';
        switch(actiontype){
            case 'edit':
                class_str = 'btn-do btn-do-edit btn-small-edit';
                break;
            case 'clone':
                class_str = 'btn-req btn-req-clone';
                break;
            case 'approvechange':
                class_str = 'btn-approvechange';
                break;
            case 'approvecancel':
                class_str = 'btn-approvecancel';
                break;
            case 'dealcancel':
                class_str = 'btn-cancel';
                break;
            default:
                class_str = 'btn-req-text btn-default';
                break;
        }
        return class_str;
    };

    $scope.getButtonText = function(button){
        var str = '';
        switch(button.actiontype){
            case 'edit':
                str = '';
                break;
            case 'clone':
                str = '';
                break;
            case 'approvechange':
                str = '';
                break;
            case 'approvecancel':
                str = '';
                break;
            default:
                str = button.text;
                break;
        }
        return str;
    };

    $scope.reclamationClick = function(deal_id){
        reclamation.set(deal_id);
        $('#bf-create-reclamation-dialog').modal('show');
    };

    $scope.showCompositeInfo = function(request_row, type) {
        $resource(API_LINK + '/deals/composition/id/' + request_row.guid + '/type/' + type
        ).get(function (result) {
            $scope.composition = result.data.data.composition
            $scope.composition_total = result.data.data.total
            $('.bf-сomposite-dialog').modal('show');
        });


    };

    $scope.buttonClick = function(request_row, button){
        switch(button.actiontype){
            case 'edit':
                request.setRequest(request_row.guid);
                request.clone_request = false;
                $('.bf-create-request-dialog').modal('show');
                break;
            case 'clone':
                request.setRequest(request_row.guid);
                request.clone_request = true;
                $('.bf-create-request-dialog').modal('show');
                break;
            case 'approvedeal':
                request.approveDeal(request_row.deal_id);
                break;
            case 'approvecancel':
                request.approveCancel(request_row.deal_id);
                break;
            case 'approvechange':
                request.approveChange(request_row.guid, request_row.order_id);
                $('.bf-create-request-dialog').modal('show');
                break;
            case 'approvebooked':
                request.approveBooked(button.deal_id);
                break;
            case 'addreclamation':
                $scope.reclamationClick(button.deal_id);
                break;
            case 'dealcancel':
                $rootScope.approveBeforeCancelDeal(request_row.cancel_id);
                break;
            default:
                break;
        }
    };
    
    $scope.manyOrders = function(){
        if(many_orders == 1){
            return true;
        }else{
            return false;
        }
    };

    this.setPurpose = function (purpose) {
      $rootScope.setOrderPurpose(purpose);
      $rootScope.renewRequests();
    };
    $rootScope.setPurpose = this.setPurpose;

    /**
     * Approve before cancel deal
     */
    $rootScope.approveBeforeCancelDeal = function (deal_id) {
        $rootScope.cancel_deal_request_id = deal_id;
        $('.bf-approve-cancel-deal-dialog').modal('show');
    };

    /**
     * Cancel deal
     * http://ec.term.biflorica:8080/api/Deals/cancel/
     * dealids%5B%5D=20
     */
    $rootScope.cancelDeal = function(){
        $http({
            method: 'POST',
            url: API_LINK + '/Deals/cancel',
            data: $.param({'dealids[]': $rootScope.cancel_deal_request_id}),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            $rootScope.cancel_deal_request_id = 0;
            $rootScope.timerCountSec = 0;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    // Выбранные значения фильтра вверху
    var upFilterValueFromCookies = $cookies.get($rootScope.filter_cookie_name);

    if(typeof upFilterValueFromCookies != 'undefined'){
        $rootScope.show_value = JSON.parse(upFilterValueFromCookies);
    }else{
        $rootScope.show_value = $rootScope.default_show_value; //.slice(0);

        $cookies.put($rootScope.filter_cookie_name, JSON.stringify($rootScope.show_value), {path: this.path_alias});
    }

    this.saveUpFilterToCookies = function(){
        $cookies.put($rootScope.filter_cookie_name, JSON.stringify($rootScope.show_value), {path: this.path_alias});
    };

    //if (!$rootScope.deals_page) {
        // Запуск действий при загрузке страницы
        $rootScope.renewRequests();
    //}

    $rootScope.isNumber = angular.isNumber;

    this.getColorCSS = function(request_row){
        return "color-"+request_row.color_id
    };


    /**
     * Merge into chain (save)
     */
    $rootScope.mergeIntoChain = function () {
        if (many_orders == 1) {
            return false;
        }

        var sent_params = {};
        sent_params['link_id'] = $scope.request.editedRequest.link_id;
        sent_params['order_id'] = $scope.show_order_id;
        sent_params['ids[]'] =  $scope.getIds();

        $http({
            method: 'POST',
            url: API_LINK + '/requests/saveLinks',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function(result) {
            console.log(result);
            result = result.data;
            if (result.errors.length == 0) {
                $scope.request.clearErrors($scope.request.editedRequest);
                $('#mergeIntoChain').modal('hide');
                $rootScope.renewRequests();
            } else {
                $scope.request.requestFormErrors = result.errors[0];
                $scope.request.requestFormFieldsErrors = result.fieldsErrors;
            }
        }, function(err) {
            console.log(err);
        });
    };

    /**
     * Merge into chain (show form)
     */
    $rootScope.mergeIntoChainShow = function () {
        if (many_orders == 1) {
            return false;
        }

        //Очищаем ошибки на форме
        $scope.request.clearErrors($scope.request.editedRequest);
        $scope.request.editedRequest.link_id = '';

        var sent_params = {};
        sent_params['order_id'] = $scope.show_order_id;
        sent_params['ids[]'] = $scope.getIds();

        $resource(
            API_LINK + '/requests/getLinks',
            sent_params,
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.request.editedRequest.links = result.data.links;
            $scope.request.editedRequest.itemsHasAtLeastOneLink = result.data.itemsHasAtLeastOneLink === 1;
            $('#mergeIntoChain').modal('show');
        });
    };

    /**
     * Remove links from requests
     */
    $rootScope.removeLinks = function () {
        url = API_LINK + '/requests/removeLinks/';
        $rootScope.querySent = true;
        $('#mergeIntoChain').modal('hide');
        var sent_params = {};
        sent_params['ids[]'] = $scope.getIds();
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if (result.errors.length != 0) {
                $('#mergeIntoChain').modal('show');
            }
            $rootScope.timerCountSec = 0;
            //$rootScope.querySent = false;
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    $rootScope.isLinkButtonShowed = function () {
        return $rootScope.current_user_role == $rootScope.role_buyer && many_orders == '0';
    }
} ]);